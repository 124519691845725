import React from 'react';
import type { HeroBannerProps, TextColorMode } from '@noths/polaris-client-ribbons-design-system';
import { HeroBanner } from '@noths/polaris-client-ribbons-design-system';
import { getAnchorElementFromEvent } from '@noths/polaris-client-utils';
import parse from 'html-react-parser';

import { SectionContainer } from 'src/components/molecules/SectionContainer/SectionContainer';
import { trackLinkClick } from 'src/tracking/common/banner';
import { trackImpression } from 'src/tracking/common/trackImpression';
import type { TrackingContext } from 'src/types/TrackingContext';
import * as styles from './HeroBannerSection.style';

const parseOptionalHtmlString = (html: string) => {
  const reactNodes = parse(html);
  return !Array.isArray(reactNodes) ? reactNodes : undefined;
};

export interface HeroBannerSectionProps extends Omit<HeroBannerProps, 'buttonHref'> {
  copy?: string;
  linkHref?: string;
  subHeading?: string;
  trackingPosition?: number;
  trackingTitle: string;
}

export const HeroBannerSection = ({
  copy,
  linkHref,
  subHeading,
  trackingPosition,
  trackingTitle,
  ...restOfProps
}: HeroBannerSectionProps) => {
  const trackingContext: TrackingContext = {
    trackingPosition,
    trackingTitle,
    trackingContentType: 'Hero banner',
  };

  return (
    <SectionContainer wrapperStyles={styles.wrapper}>
      <HeroBanner
        buttonHref={linkHref}
        copy={copy && parseOptionalHtmlString(copy)}
        imageLinkHref={linkHref}
        onButtonClick={(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
          if (getAnchorElementFromEvent(e)) {
            trackLinkClick(e, trackingContext, 'CTA');
          }
        }}
        onImageLinkClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          trackLinkClick(e, trackingContext, 'Image')
        }
        onLinkClick={(e: React.MouseEvent) => trackLinkClick(e, trackingContext, 'Link')}
        onVisible={() => trackImpression(trackingContext)}
        subHeading={subHeading && parseOptionalHtmlString(subHeading)}
        {...restOfProps}
      />
    </SectionContainer>
  );
};

export type { HeroBannerProps, TextColorMode };
