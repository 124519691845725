/* istanbul ignore file */
import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const wrapper = css`
  margin: ${stack(spacing.xl)};

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      margin: ${stack(spacing.xxxl)};
    `,
  )}
`;
