import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';
import { getAnchorElementFromEvent } from '@noths/polaris-client-utils';

import type { TrackingContext } from 'src/types/TrackingContext';

type ElementType = 'CTA' | 'Image' | 'Link';

export const trackLinkClick = (
  e: React.MouseEvent | React.MouseEvent<HTMLAnchorElement>,
  trackingContext: TrackingContext,
  elementType: ElementType,
) => {
  const { trackingContentType, trackingPosition, trackingTitle } = trackingContext;
  const element = getAnchorElementFromEvent(e);

  if (element) {
    const trackingEvent = {
      event: 'custom_event',
      event_action: `${elementType} click`,
      event_category: `${trackingPosition} | ${trackingContentType}`,
      event_label: `${trackingPosition} | ${trackingTitle} | ${elementType}`.toLowerCase(),
      event_name: 'select_content',
      event_details: `${elementType} click`.toLowerCase(),
      content_position: `${trackingPosition}`,
      content_type: `${trackingContentType}|${trackingTitle}`.toLowerCase(),
      destination_URL: element.href,
    };

    sendGALinkClickEvent(e, trackingEvent);
  }
};
